.testimonies-container {
    background: url("../../images/testimonies_banner.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: right;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.testimonies-profiles{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 7rem;
}

.testimonies-carousel {
    width: 55%;
    height: 50%;
    padding: 20px;
    background-color: #B0B0B0A1;
    border-radius: 20px;
}

.testimonies-carousel .carousel-indicators {
    margin-bottom: -3rem;
}

.testimonies-carousel .carousel-indicators > li {
    border-radius: 100%;
    height: 15px;
    width: 15px;
}

.testimonies-profiles .carousel .carousel-inner .carousel-item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.testimonies-head-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 66.1206px;
    line-height: 80px;
    color: #FFFFFF;
}

.testimonies-carousel .carousel-inner .carousel-item > img {
    height: 100%;
    width: 100%;
}

@media (max-width:964px) {
    .testimonies-container {
        background-size: 100% 100%;
        flex-direction: column-reverse;
    }

    .testimonies-head-container {
        margin-top: 20%;
    }

    .testimonies-head-title > span {
        color: black;
    }

    .testimonies-profiles {
        width: 100%;
        justify-content: center;
        padding: 0;
    }

    .testimonies-carousel {
        height: 60%;
        width: 60%;
    }
}

@media (max-width:432px) {
    /* .testimonies-container {
        height: 100%;
    } */

    .testimonies-head-container {
        margin-top: 35%;
    }

    .testimonies-head-title {
        font-size: 40px;
        color: black;
        line-height: 40px;
    }

    .testimonies-carousel {
        height: 280px;
        width: 280px;
        margin-bottom: 10%;
    }
}