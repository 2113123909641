@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.hero-container {
    height: 100vh;
}

.hero-box {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    gap: 1rem;
    position: relative;
}

.hero-box-title {
    display: flex;
    justify-content: flex-start;
}

.hero-box-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15.0857px;
    line-height: 18px;
    text-align: justify;

    color: #FFFFFF;
}

.hero-box-text-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30.0857px;
    line-height: 18px;
    text-align: justify;

    color: #FFFFFF;
}

.hero-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.main-form-container {
    width: 60%;
    background: #FFFFFF;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.72);
    border-radius: 40px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    height: 50vh;
}

.form-head-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 35.7259px;
    line-height: 43px;
    text-align: left;
    color: #000000;
}

.main-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.form-control {
    background: #EFEFEF;
    border-radius: 10px;
}

.hero-btn {
    background-color: #EECC7C;
    box-shadow: 0px 5.04666px 27.3684px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 18.2456px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    margin-top: 1rem;
    color: #000000;
    width: 90%;
    padding: 10px;
    transition: all 0.2s ease-in-out;
}

.hero-btn:hover {
    background-color: #E2BA5D;
}

.whatsapp-icon-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    height: 4rem;
    width: 4rem;
    z-index: 2;
}

.whatsapp-icon-container > a > img {
    height: 100%;
    width: 100%;
}

@media (max-width:920px) {
    .row {
        flex-direction: column-reverse;
    }

    .hero-form {
        margin-left: 0;
        align-items: center;
        justify-content: center;
        height: auto;
        margin-top: 25%;
    }

    .main-form-container {
        height: auto;
        width: auto;
        align-items: center;
    }

    .form-head-title {
        font-size: 50px;
    }

    .main-form {
        padding: 0;
        width: 90%;
        margin-top: 5%;
    }

    .main-form > input {
        font-size: 24px;
    }

    .hero-btn {
        width: 100%;
    }

    .hero-box {
        height: auto;
    }

    .hero-box-title {
        padding-top: 15%;
    }
    
    .hero-box-title > svg {
        height: 130px;
    }

    .hero-box-text {
        font-size: 16px;
        font-weight: 600;
    }
}

@media (max-width:432px) {
    .hero-box-text {
        display: none;    
    }

    .hero-form {
        padding-top: 40%;
        margin-left: 0;
        align-items: center;
        justify-content: center;
        height: auto;
        margin-top: 0;
    }

    .main-form-container {
        height: auto;
        width: 95%;
        padding: 1.5rem 0.5rem;
        align-items: center;
    }

    .hero-btn {
        width: 100%;
    }

    .hero-box {
        height: auto;
        display: flex;
        align-items: center;
    }

    .hero-box-text-1 {
        font-size: 32px;
    }

    .hero-box-title {
        padding-top: 10%;
    }
    
    .hero-box-title > svg {
        height: 80px;
    }

    .form-head-title {
        font-size: 35.7259px;
    }

    .main-form {
        width: 80%;
        margin-top: 0;
    }

    .main-form > input {
        font-size: 18px;
    }
}

@media (max-width:342px) {
    .hero-box-text {
        display: none;    
    }

    .hero-form {
        padding-top: 40%;
        margin-left: 0;
        align-items: center;
        justify-content: center;
        height: auto;
        margin-top: 0;
    }

    .hero-btn {
        width: 100%;
        font-size: 13px;
        margin-top: 0;
    }

    .hero-box-text-1 {
        font-size: 22px;
    }

    .hero-box-title {
        padding-top: 10%;
    }
    
    .hero-box-title > svg {
        height: 50px;
    }

    .form-head-title {
        font-size: 30.7259px;
    }

    .main-form {
        width: 80%;
        margin-top: 0;
    }

    .main-form > input {
        font-size: 14px;
    }
}