@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.commercial-container {
    background: url("../../images/commercial_page_banner.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
}

.commercial-inner-container {
    background: rgba(0, 0, 0, 0.49);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.commercial-container-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 4px solid #EECC7C;
}

.commercial-inner-container .row {
    --bs-gutter-x: 0;
}

.commercial-inner-container .row .col-sm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.commercial-container-image {
    height: 100%;
    width: 50%;
}

.commercial-container-image > img {
    height: 100%;
    width: 100%;
}

.commercial-container-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 1rem;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
}

.commercial-content-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: #000000;
    text-align: left;
}

.commercial-content-text {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
}

.commercial-content-list {
    width: 80%;
    color: #EECC7C;
}

.commercial-content-list > ul {
    text-align: left;
    margin-left: 1rem;
    color: #EECC7C;
    list-style-type: square;
    font-size: 22px;
}

.commercial-content-list > ul > li > span {
    text-align: left;
    margin-left: 1rem;
    font-size: 18px;
    color: #000000;
}

.commercial-content-button {
    background-color: #EECC7C;
    border-radius: 10px;
    width: 25%;
    padding: 5px 15px;
    transition: all 0.2s ease-in-out;
}

.commercial-content-button > a {
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.commercial-content-button:hover {
    background-color: #d2b46c;
}

.commercial-content-button > a:hover {
    color: black;
}

@media (max-width:920px) {
    .commercial-container {
        background-size: 100% 100%;
    }

    .commercial-inner-container {
        justify-content: flex-end;
    }

    .commercial-inner-container .row {
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 5%;
    }
}

@media (max-width:432px) {
    .commercial-container {
        height: 100%;
    }

    .commercial-inner-container {
        height: 100%;
    }

    .commercial-container-head {
        font-size: 32px;
        margin-top: 30%;
    }

    .commercial-container-content {
        width: 90%;
    }

    .commercial-content-head {
        font-size: 24px;
    }
    
    .commercial-content-text {
        font-size: 14px;
    }
    
    .commercial-content-list {
        width: 100%;
    }
    
    .commercial-content-list > ul {
        font-size: 18px;
        margin-left: 0;
    }
    
    .commercial-content-list > ul > li > span {
        font-size: 14px;
        margin-left: 0;
    }
    
    .commercial-content-button {
        width: 50%;
    }
    
    .commercial-content-button > a {
        font-size: 16px;
    }
}