.navbar {
    background: rgba(159, 153, 141, 0.68);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    position: fixed;
    width: 90%;
    margin-top: 2.5rem;
    margin-left: 5%;
}

.me-auto {
    width: 100%;
    justify-content: flex-end;
    gap: 3rem;
}

.me-auto .nav-link {
    color: white;
    font-size: 1.2rem;
}

.nav-link:nth-child(5) {
    color: black;
    font-weight: 600;
    background: #F1CD76;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

@media (max-width:964px) {
    .me-auto {
        gap: 0.5rem;
    }

    .navbar {
        background: rgba(159, 153, 141, 0.9);
    }
}

@media (max-width:432px) {
    .navbar {
        background: rgba(159, 153, 141, 0.9);
    }
}