@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.services-container {
    background: url("../../images/services_page_banner.png");
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
}

.services-inner-container {
    background: rgba(0, 0, 0, 0.38);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.services-container-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 7px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 4px solid #EECC7C;
}

.services-container-sub-head {
    background: rgba(0, 0, 0, 0.45);
    border-radius: 35px;
    color: white;
    padding: 5px 20px;
    font-size: 24px;
    font-weight: 600;
}

.services-icons-container {
    background: rgba(227, 227, 227, 0.65);
    border-radius: 35px;
    width: 60%;
    padding: 0 5% 1% 5%;
    display: flex;
    flex-wrap: wrap;
    height: 50%;
    margin-bottom: -2%;
    justify-content: space-evenly;
    align-items: center;
}

.services-icon-container {
    height: 80px;
    width: 160px;
}

.services-icon-container > img {
    height: 100%;
    width: auto;
}

.services-icon-name {
    background-color: #EECC7C;
    border-radius: 10px;
    padding: 2px 10px;
    margin-top: 0.7rem;
}

@media (max-width:920px) {
    .services-container {
        background-size: 100% 100%;
    }

    .services-icons-container {
        width: 60%;
        padding: 0 5% 5% 5%;
    }
}

@media (max-width:432px) {
    .services-container {
        height: 110vh;
    }

    .services-inner-container {
        height: 100%;
    }

    .services-container-head {
        font-size: 40px;
        margin-top: 15%;
    }

    .services-inner-container {
        gap: 1rem;
    }

    .services-icons-container {
        width: 90%;
        padding: 0 2% 5% 2%;
        height: 65%;
        margin-bottom: -10%;
    }

    .services-icon-container {
        height: 60px;
    }

    .services-icon-name {
        font-size: 12px;
    }

    .services-container-sub-head {
        font-size: 18px;
    }
}

@media (max-width:342px) {
    .services-container {
        height: 120vh;
        background-size: cover;
    }

    .services-inner-container {
        height: 100%;
        gap: 1rem;
        background-size: cover;
    }

    .services-container-head {
        font-size: 36px;
        margin-top: 35%;
    }

    .services-icons-container {
        width: 95%;
        padding: 0 2% 5% 2%;
        height: 50%;
    }

    .services-icon-container {
        height: auto;
        width: 45%;
        margin-top: 0.5rem;
    }

    .services-icon-container > img {
        height: auto;
        width: 30%;
    }

    .services-icon-name {
        font-size: 12px;
    }

    .services-container-sub-head {
        font-size: 18px;
    }
}